.WhyOurProducts-cont {

 
}
.WhyOurProducts-cont .title h1 {
  margin-top: 30px;
  text-align: left;
  margin-left: 70px;
  color: #4f4f4f;
  font-family: "Montserrat", sans-serif;
}
.WhyOurProducts-cont .advantages h4 {
  color: #4f4f4f;
  font-family: "Montserrat", sans-serif;
}
span {
  color: #929292;
  text-align: left;
  font-family: "Montserrat", sans-serif;
}
.WhyOurProducts-cont .advantages img {
  width: 50px;
  height: 50px;
  position: absolute;
}
.WhyOurProducts-cont {
  background-color: #efefef;
  height: 70vh;
  display: flex;
  justify-content: center;
}
.WhyOurProducts-cont .title {
  width: 35%;
  margin-top: 30px;
}
.WhyOurProducts-cont .advantages {
  display: flex;
  flex-wrap: wrap;
  width: 65%;
  justify-content: center;
  margin-top: 20px;
}
.WhyOurProducts-cont .advantage1,
.advantage2,
.advantage3,
.advantage4,
.advantage5,
.advantage6 {
  width: 30%;
  height: 10vh;
  padding: 1rem;
}

.WhyOurProducts-cont .advantage1 .circle,
.WhyOurProducts-cont .advantage2 .circle,
.WhyOurProducts-cont .advantage3 .circle,
.WhyOurProducts-cont .advantage4 .circle,
.WhyOurProducts-cont .advantage5 .circle,
.WhyOurProducts-cont .advantage6 .circle {
  border: 2px solid #67f8e0;
  border-radius: 50%;
  background-color: #67f8e0;
  width: 50px;
  height: 40px;
}
.WhyOurProducts-cont .advantage1 .advImg1,
.advantage2 .advImg2,
.advantage3 .advImg3,
.advantage4 .advImg4,
.advantage5 .advImg5,
.advantage6 .advImg6 {
  display: flex;
  justify-content: center;
}
.WhyOurProducts-cont .advantage1 .advTitle1,
.advantage2 .advTitle2,
.advantage3 .advTitle3,
.advantage4 .advTitle4,
.advantage5 .advTitle5,
.advantage6 .advTitle6 {
  margin-top: 36px;
}
.WhyOurProducts-cont .advantage1 .advImg1 img,
.WhyOurProducts-cont .advantage2 .advImg2 img,
.WhyOurProducts-cont .advantage3 .advImg3 img,
.WhyOurProducts-cont .advantage4 .advImg4 img,
.WhyOurProducts-cont .advantage5 .advImg5 img,
.WhyOurProducts-cont .advantage6 .advImg6 img {
  margin-top: 10px;
  margin-left: 8px;
}
@media only screen and (max-width: 1024px) {
  .WhyOurProducts-cont {
  
  }
}

@media only screen and (max-width: 768px) {
  .WhyOurProducts-cont .title {
    display: none;
  }
  .WhyOurProducts-cont .advantages {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .WhyOurProducts-cont .title {
    display: none;
  }
  .WhyOurProducts-cont .advantages {
    width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .WhyOurProducts-cont .advantages h4 {
    font-size: 10px;
  }
  span {
    font-size: 10px;
  }
  .WhyOurProducts-cont {
    height: 65vh;
  }
  .WhyOurProducts-cont .advantage1 .advDescription1,
  .WhyOurProducts-cont .advantage2 .advDescription2,
  .WhyOurProducts-cont .advantage3 .advDescription3,
  .WhyOurProducts-cont .advantage4 .advDescription4,
  .WhyOurProducts-cont
    .advantage5
    .advDescription5
    .WhyOurProducts-cont
    .advantage6
    .advDescription6 {
    display: none;
  }
  .advDescription5,
  .advDescription6 {
    display: none;
  }
}
@media only screen and (max-width: 375px) {
  .WhyOurProducts-cont {
    height: 95vh !important;
  }
  .writing {
    left: 12px;
    top: 200px;
  }
}
@media only screen and (max-width: 320px) {
  .fi-img {
    width: 255px;
  }
  .fii-button-class {
    font-size: 13px;
  }
  .WhyOurProducts-cont .advantage1 .advDescription1,
  .WhyOurProducts-cont .advantage2 .advDescription2,
  .WhyOurProducts-cont .advantage3 .advDescription3,
  .WhyOurProducts-cont .advantage4 .advDescription4,
  .WhyOurProducts-cont
    .advantage5
    .advDescription5
    .WhyOurProducts-cont
    .advantage6
    .advDescription6 {
    display: none;
  }
  .advDescription5,
  .advDescription6 {
    display: none;
  }
}
