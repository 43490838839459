.account {
  .info {
    margin-bottom: 10px;
    @include flex();
    // flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      align-items: normal;
    }

    .desc {
      flex: 1;
      @include media-breakpoint-down(xs) {
        @include flex();
        justify-content: space-between;
        align-items: center;
      }

      .provider-email {
        text-transform: capitalize;
      }
    }

    p,
    span {
      margin: 0;
      display: inline;
    }
  }
}
