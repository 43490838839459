// layout styles

html {
  overflow: visible;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  background-color: $white;
  color: $font-color !important;
  font-family: $font-family-body;
  font-size: $font-size-small;
  font-weight: $font-weight-normal;
  height: 100%;
  letter-spacing: $letter-spacing;
  line-height: $line-height;
  margin: 0;
  padding: 0;
  position: relative;
  text-rendering: optimizeLegibility;
  // -webkit-font-smoothing: antialiased;
}

.application {
  @include flex();
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 0 auto;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  margin: 0;
}

.wrapper {
  padding: 0px 0px 25px;

  @include media-breakpoint-up(md) {
    padding: 25px 0px;
  }
}

img {
  border-style: none;
  vertical-align: middle;
}

span,
label,
a,
p {
  font-size: $font-size-medium;
}

label {
  cursor: default;
  text-transform: capitalize;
  margin: 0;
  font-weight: $font-weight-bold-one;
}

a {
  color: $font-color !important;
  text-decoration: none !important;
  -moz-transition: color $layout-transition-speed ease-in-out;
  -webkit-transition: color $layout-transition-speed ease-in-out;
  -ms-transition: color $layout-transition-speed ease-in-out;
  transition: color $layout-transition-speed ease-in-out;

  &:hover {
    color: $font-hover-color !important;
    -moz-transition: color $layout-transition-speed ease-in-out;
    -webkit-transition: color $layout-transition-speed ease-in-out;
    -ms-transition: color $layout-transition-speed ease-in-out;
    transition: color $layout-transition-speed ease-in-out;
  }

  &:focus {
    outline: none;
  }
}

body.user-is-tabbing a:focus {
  box-shadow: none;
  outline: 1px dotted;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $font-heading-color !important;
  font-family: $font-family-heading;
  font-size: $font-size-heading-medium !important;
  line-height: $line-height !important;
  text-transform: capitalize;
}

h1,
.h1 {
  font-size: 2em !important;
}

h2,
.h2 {
  font-size: 1.4em !important;
}

h3,
.h3 {
  font-size: 1.27em !important;
}

h4,
.h4 {
  font-size: 1.12em !important;
}

h5,
.h5 {
  font-size: 0.83em !important;
}

h6,
.h6 {
  font-size: 0.75em !important;
}

/* scrollbar styles */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: $white;
  border-left: $border-default;
  border-color: $theme-light-white;
}

::-webkit-scrollbar-track {
  background: $theme-dark-gray;
}
/* scrollbar styles */

body.user-is-tabbing *:focus {
  // outline: 2px solid $outline-color !important;
  // outline: 2px auto -webkit-focus-ring-color !important;
  // outline: 1px dotted !important;
  outline: none;
  box-shadow: $outline-box-shadow;
  border-radius: $border-radius-default;
}
