// header styles
.header-info {
  padding: 10px 0px;
  background-color: $theme-light-black;

  .fa,
  span {
    color: $white;
    font-size: $font-size-medium !important;
    font-weight: $font-weight-bold-one;
    margin: 0px 5px;
  }
}

.header {
  background-color: $white;
  border-bottom: $border-primary;

  .logo {
    font-size: $font-size-heading-medium !important;
  }
}

.fixed-mobile-header {
  @include media-breakpoint-down(sm) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 998;


  }
}

.top-header {
  // margin-top: 10px;
  // margin-bottom: 10px;
  // height: 95px;
  padding: 16px 0px;

  @include media-breakpoint-up(lg) {
    // height: 60px;

    padding: 22px ​0px;
  }

  .bars-icon {
    &:hover {
      opacity: 0.5;
    }
  }

  .cart-icon {
    position: relative;
    line-height: 100%;

    &:hover {
      opacity: 0.5;
    }
  }

  .fa {
    cursor: pointer;
    color: $theme-dark-gray;
    font-size: $font-size-x-huge !important;
  }

  .col-no-padding {
    @include media-breakpoint-down(sm) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .navbar {
    background-color: $white !important;
    justify-content: flex-end;
    padding: 0;

    @include media-breakpoint-down(sm) {
      padding-top: 0;
      padding-bottom: 0.5rem;
      justify-content: center;
    }

    .cart-icon {
      .bag-icon {
        vertical-align: text-bottom;
      }
    }
  }

  .navbar-nav {
    flex-direction: row;
    @include media-breakpoint-up(md) {
      margin-left: 20px;
    }

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;

      &.active {
        color: $font-hover-color !important;
      }
    }

    a,
    span {
      &:hover {
        color: $font-hover-color !important;
      }
    }

    .dropdown-toggle.nav-link {
      text-transform: capitalize;
    }

    .dropdown-menu {
      &.nav-brand-dropdown {
        padding: 0;
        @include media-breakpoint-down(sm) {
          left: -30px;
          min-width: 270px;
        }
      }
    }

    .nav-item {
      position: relative;
      @include media-breakpoint-down(sm) {
        margin-right: 15px;
      }
    }
  }

  .brand {
    @include flex();
    flex-wrap: wrap;
    align-items: flex-start;
    @include media-breakpoint-down(sm) {
      margin-top: 10px;
      justify-content: center;
    }

    h1 {
      margin: 0px;
      color: $font-custom-color !important;
      @include media-breakpoint-up(md) {
        margin-left: 15px;
      }
    }
  }

  .cart-badge {
    border-radius: 100%;
    font-size: 0.6rem;
    font-weight: 600;
    position: absolute;
    top: -12px;
    right: -14px;
    text-align: center;
    width: 20px;
    height: 20px;
    background-color: $primary-bg;
    color: $white;
    @include flex();
    justify-content: center;
    align-items: center;
  }

  .header-links {
    @include flex();
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

// mini brand
.mini-brand {
  min-width: 270px;
  @include media-breakpoint-up(md) {
    min-width: 500px;
  }

  .min-brand-title {
    border-bottom: $border-primary;
    padding-bottom: 8px;
    margin-bottom: 16px;
  }
}

.mini-brand-list {
  padding: 16px 20px;
  @include media-breakpoint-up(md) {
    padding: 20px 40px;
  }

  .mini-brand-block {
    @include flex();
    flex-wrap: wrap;
    margin-top: 12px;

    .brand-item {
      flex: 100%;
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        flex: 50%;
      }

      .brand-link {
        position: relative;
        color: $font-custom-color !important;
        font-size: $font-size-large;
        font-weight: $font-weight-bold-one;

        &:hover {
          &:after {
            opacity: 1;
            -webkit-transform: translateY(2px);
            -moz-transform: translateY(2px);
            transform: translateY(2px);
          }
        }

        &:after {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $primary-bg;
          content: '';
          opacity: 0;
          -webkit-transition: opacity $layout-transition-speed,
            -webkit-transform $layout-transition-speed;
          -moz-transition: opacity $layout-transition-speed,
            -moz-transform $layout-transition-speed;
          transition: opacity $layout-transition-speed,
            transform $layout-transition-speed;
          -webkit-transform: translateY(10px);
          -moz-transform: translateY(10px);
          transform: translateY(10px);
        }
      }
    }
  }
}
