.newsletter-form {
  p {
    margin: 0;
  }
}

.subscribe {
  .inline-btn-box {
    .input-text-block {
      @include media-breakpoint-between(md, lg) {
        flex-direction: column;

        .input-text {
          border-radius: $border-radius-default;
        }

        .custom-btn-primary {
          margin-top: 10px;
          border-left: $border-default !important;
          border-radius: $border-radius-default !important;
        }
      }
    }
  }
}
