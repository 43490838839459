
/* Chatbox */

.chatbox {
  color: #000000;
  position: inherit;
  right: 1rem;
  bottom: 1rem;
}
.chatbox ul {
  overflow: scroll;
  max-height: 20rem;
}
.chatbox li {
  margin-bottom: 1rem;
}
.chatbox input {
  width: calc(100% - 3.5rem);
}

.support-users {
  background: #f0f0f0;
}

.support-users li {
  background-color: #f8f8f8;
}
.support-users button {
  background-color: transparent;
  border: none;
  text-align: left;
}
.support-users li {
  margin: 0;
  background-color: #f0f0f0;
  border-bottom: 0.1rem #c0c0c0 solid;
  padding-top: 10px;
  padding-bottom: 10px;
}

.support-users li:hover {
  background-color: #f0f0f0;
}
.support-users li.selected {
  background-color: #c0c0c0;
}

.chatButton {
  border: 0.1rem #007bff solid !important;
}
.support-messages input {
  width: calc(100% - 4rem);
}
.support-messages ul {
  height: calc(100vh - 18rem);
  max-height: calc(100vh - 18rem);
  overflow: scroll;
}
.support-messages li {
  margin-bottom: 1rem;
}

.support-users span {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  position: absolute;
  margin-left: 25px;
  margin-top: 3.5px;
}
.support-users .offline {
  background-color: #808080;
}
.support-users .online {
  background-color: #20a020;
}
.support-users .unread {
  background-color: #f02020;
}
