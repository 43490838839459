/* start autosuggest styles */
.react-autosuggest__container {
  position: relative;

  .react-autosuggest__input {
    height: 40px;
    padding: 10px 20px;
    border: $border-default;
    border-radius: 4px;
    -webkit-appearance: none;
  }

  .react-autosuggest__input::-ms-clear {
    display: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    width: 100%;
    font-weight: 300;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    border: $border-default;
    background-color: $white;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow: auto;
    max-height: 400px;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: $theme-gray;
  }

  .react-autosuggest__section-container--first {
    border-top: 0;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow: auto;
    max-height: 400px;
  }

  .react-autosuggest__suggestion-match {
    color: $black;
    font-weight: $font-weight-bold-one;
  }

  .item-image {
    width: 50px;
    height: 50px;
    border-radius: $border-radius-primary;
  }
}

/* end autosuggest styles */
