.spinner-container {
  height: 100%;
  top: 0;
  width: 100%;
  left: 0;
}

.spinner-container {
  &.overlay {
    z-index: 3000;
  }

  &.backdrop {
    background-color: $transparent-white-bg;
  }
}

.spinner {
  bottom: 0;
  left: 0;
  right: 0;
  top: 25%;
  z-index: 1022;
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: $theme-light-blue;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
