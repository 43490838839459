* {
  font-family: "Raleway", sans-serif !important;
}

.App {
  text-align: center;
  margin: 0;
  padding: 0;
  width: 100% !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  order: 1;
  width: 60px;
  height: 60px;
  margin-bottom: 0.5rem;
}

.logo-header {
  display: flex;
  flex-direction: row;
}

.spotlight {
  align-content: center;
  text-align: center;
}

.bg-img {
  height: 95vh;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}
.hero-centered {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .hero-centered {
    position: absolute;
    top: 200%;
    left: 50%;
    transform: translate(200%, -100%);
    font-family: "Montserrat", sans-serif;
    margin-top: 10vh;
  }
}

.w-header {
  font-size: 50px;
  font-weight: 500;
  padding-bottom: 10px;
}

.w-body {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 5px;
}

.w-button-class {
  background-color: white;
  color: black;
  border: 2px solid #fff;
  height: 6vh;
  width: 18vh;
  border-radius: 5vh;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.w-button-class:hover {
  background-color: #e7e7e7;
  border: 2px solid #fff;
}

.w-button-body {
  align-items: center;
  padding: 2vh;
  align-self: center;
  left: 40%;
}

/* .infocont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  justify-content: center;
} */

.infocont {
  display: flex;
  flex-direction: row;
}

.comp-info-box {
  flex: 1 0 21%; /* explanation below */
  margin: 5px;

  margin: auto;
  text-align: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  padding-left: 2vh;
  padding-right: 2vh;
  vertical-align: middle;
  align-items: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.info-box {
  flex: 1 0 21%; /* explanation below */
  margin: 5px;
  height: 32vh;

  margin: auto;
  text-align: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  padding-left: 2vh;
  padding-right: 2vh;
  vertical-align: middle;
  align-items: center;
  padding-top: 7vh;
}

.info-title {
  padding: 10px;
  font-weight: 600;
  flex: 1;
}

.info-details {
  font-size: 14px;
  height: 40px;
}

.info-img {
  width: 40px;
  height: 40px;
}

.ci {
  background-color: #efefef;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  align-content: center;
  height: 15vh;
  display: flex;
  vertical-align: middle;
  width: 100%;
}

.ci-box {
  width: 25%;
  font-family: "Montserrat", sans-serif;
  margin-left: 2vh;
  margin-right: 2vh;
  text-align: center;
  display: inline;
}

.ci-elements {
  display: inline-flex;
  align-items: center;
  align-content: center;
  align-self: flex-end;
}

.ci-img {
  width: 25px;
  height: 25px;
}

.ci-text {
  margin-left: 2vh;
}

.product-listt{
  grid-template-columns: repeat(2, 1fr);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;

}

.categorySelect{
  background-color: white;
  padding-left: 0 !important;
}


@media only screen and (max-width: 1024px) {
  .infocont {
    display: flex;
    justify-content: space-between;
  }
}

.hero-container {
  position: relative;
  text-align: center;
  color: black;
}

.hero-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Montserrat", sans-serif;
}

.bottom-left {
  position: absolute;
  bottom: 50px;
  left: 50px;
  font-size: 16px;
  font-weight: bold;
}

.color-black {
  color: #000;
}
.color-white {
  color: #fff;
}

.carosel-header {
  font-size: 16px;
  font-weight: bold;
  height: 10vh;
  display: flex;
  vertical-align: middle;
  text-align: center;
}

.carosel-header div {
  align-self: center;
  font-size: 30px;
  font-weight: 500;
  color: #000;
  align-items: center;
}

.align-items-center {
  justify-content: space-between !important;
}

@media (max-width: 800px) {
  .infocont {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
  .info-box {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .carosel-header .bottom-left {
    position: absolute;
    bottom: 30px;
    left: 50px;
    font-size: 16px;
    font-weight: bold;
  }
}
@media only screen and (max-width: 567px) {
  .signup-form {
    margin-top: 260px;
  }
  .shop {
    margin-top: 260px;
  }
  .contact {
    margin-top: 260px;
  }
  .sell {
    margin-top: 260px;
  }
  .sell .agreement-banner {
    margin: 0 auto;
    width: 350px !important;
}

  .homepage {
    margin-top: 260px;
  }
  .order-success {
    margin-top: 260px;
  }
  .admin {
    margin-top: 270px;
  }
  .bg-img {
    height: 30vh;
  }
  .container-fluid {
    justify-content: center !important;
  }
  .hero-centered {
    top: 0 !important;
  }
  .block-content ul {
    padding-left: 0 !important;
  }
  .align-items-center {
    justify-content: space-between !important;
  }
}
@media (max-width: 540px) {
  .carosel-header div {
    align-self: center;
    font-size: 20px;
    font-weight: 500;
    color: #000;
    align-items: center;
  }
  .w-body {
    font-size: 10px;
    font-weight: 500;
    padding-bottom: 5px;
  }
  .w-header {
    font-size: 20px;
  }
  .px-3 {
    margin-top: 270px !important;
  }
  .policy-container {
    margin-top: 280px;
  }
  .sub-page .subpage-header {
    margin-top: 250px;
  }
}
@media (max-width: 460px) {
  .carosel-header div {
    align-self: center;
    font-size: 20px;
    font-weight: 500;
    color: #000;
    align-items: center;
  }
  .w-body {
    font-size: 10px;
    font-weight: 500;
    padding-bottom: 5px;
  }
  .w-header {
    font-size: 20px;
  }
  .top-header .brand h1,
  .top-header .brand .h1 {
    width: 100%;
  }
}
@media (max-width: 280px) {
  .hero-centered {
    top: 17px !important;
}
.w-header {
  font-size: 13px;
}

.w-body {
  font-size: 8px;
}


}

.top-header .brand {
  align-items: center !important;
}
.top-header .brand h1,
.top-header .brand .h1 {
  display: flex;
  align-items: center;
  font-weight: bold;
}
.logo-header {
  margin-left: 14px;
}
