.order-dashboard {
  .order-list {
    .order-details {
      @include flex();
      flex-direction: column;
      height: 100%;
    }

    .order-box {
      border-radius: $border-radius-default;
      box-shadow: $box-shadow-secondary;

      .order-first-item {
        align-self: center;

        .item-image {
          border-top-right-radius: $border-radius-default;
          border-top-left-radius: $border-radius-default;

          @include media-breakpoint-up(lg) {
            border-radius: $border-radius-default;
          }

          @include media-breakpoint-up(lg) {
            width: 90px;
            height: 90px;
          }
        }
      }
    }
  }
}

/* start order success styles */
.order-success {
  .order-message {
    text-align: center;
  }

  .order-success-actions {
    text-align: center;

    .btn-link {
      margin-right: 10px;

      @include media-breakpoint-down(xs) {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}
/* end order success styles */

/* start order meta styles */
.order-meta {
  .title {
    border-bottom: $border-primary;
    padding-bottom: 8px;
    margin-bottom: 16px;
  }

  p {
    text-transform: capitalize;
    margin-bottom: 5px;
  }
}
/* end order meta styles */

/* start order items styles */
.order-items {
  h2 {
    border-bottom: $border-primary;
    padding-bottom: 8px;
    margin-bottom: 16px;
  }

  .item {
    &:last-child {
      .order-item-box {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  .order-item-box {
    border-bottom: $border-primary;
    padding-bottom: 16px;
    margin-bottom: 16px;

    @include media-breakpoint-down(xs) {
      .dropdown-confirm {
        .btn-group {
          width: 100%;

          .nav-link {
            width: 100%;
            text-align: center;
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      .box {
        width: 50%;
      }
    }

    .dropdown-confirm {
      &.admin {
        .dropdown-menu {
          min-width: 150px;
        }
      }
    }

    .item-details {
      max-width: 200px;
    }

    .item-image {
      width: 80px;
      border-radius: $border-radius-default;
    }
  }

  p {
    margin-bottom: 0;
  }

  .price {
    font-size: $font-size-medium;
  }
}
/* end order items styles */

/* start order summary styles */
.order-summary {
  border: $border-primary;

  h2 {
    border-bottom: $border-primary;
    padding-bottom: 8px;
    margin-bottom: 16px;
  }
}
/* end order summary styles */

.order-status {
  color: $default-color !important;
}

/* start order common styles */
.order-label {
  font-weight: $font-weight-bold-two;
  color: $font-custom-color;
}
/* end order common styles */
