.radio {
  ul {
    list-style: none;
    margin: 0 0 1px;
    padding: 0;
  }

  li:not(:last-child) {
    margin-bottom: 5px;
  }

  label {
    display: flex;
    align-items: center;
  }

  input[type='radio'] {
    margin: 0 10px 0 0;
  }
}
