* {
  font-family: "Montserrat", sans-serif;
}
.guarantee-cont {
  background-color: #a9f3da;
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.guarantee-cont .cont1 {
  margin: 30px;
}
.guarantee-cont .cont1 h1 {
  color: #454847;
  font-weight: bold;
}
.guarantee-cont .cont1 span {
  color: #779e91;
  font-weight: bold;
}
.guarantee-cont .cont2 {
  margin: 30px;
}
.guarantee-cont .cont2 p {
  margin: 30px;
  font-size: 20px;
  color: #779e91;
  font-weight: bold;
}
.guarantee-cont .cont2 h1 {
  color: #49504d;
  font-weight: bold;
}
@media only screen and (max-width: 425px) {
  .guarantee-cont .cont1 h1 {
    margin: 0;
    /* font-size: 30px; */
  }
  .guarantee-cont .cont2 h1 {
    margin: 0;
    /* font-size: 30px; */
  }
}
