.product-reviews {
  .fa {
    font-size: $font-size-x-huge;
  }

  .review-summary {
    .left {
      width: 20%;

      @include media-breakpoint-up(md) {
        width: 25%;
      }
      @include media-breakpoint-up(lg) {
        width: 15%;
      }
    }
    .middle {
      width: 60%;

      @include media-breakpoint-up(md) {
        width: 50%;
      }
      @include media-breakpoint-up(lg) {
        width: 70%;
      }
    }
    .right {
      width: 20%;
      text-align: right;

      @include media-breakpoint-up(md) {
        width: 25%;
      }

      @include media-breakpoint-up(lg) {
        width: 15%;
      }
    }

    .bar-container {
      width: 100%;
      background-color: #f1f1f1;
      text-align: center;
      color: white;
    }
    .bar-5 {
      height: 16px;
      background-color: #4caf50;
    }
    .bar-4 {
      height: 16px;
      background-color: #2196f3;
    }
    .bar-3 {
      height: 16px;
      background-color: #00bcd4;
    }
    .bar-2 {
      height: 16px;
      background-color: #ff9800;
    }
    .bar-1 {
      height: 16px;
      background-color: #f44336;
    }
  }

  .review-list {
    .review-box {
      height: 100%;
      border-radius: $border-radius-default;
      box-shadow: $box-shadow-secondary;
      background-color: $white;

      .avatar {
        width: 40px;
        height: 40px;
      }

      .review-icon {
        width: 35px;
        height: 35px;

        @include media-breakpoint-up(lg) {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}

.review-dashboard {
  .r-list {
    .review-box {
      border-radius: $border-radius-default;
      box-shadow: $box-shadow-secondary;

      .avatar {
        width: 30px;
        height: 30px;
        font-size: $font-size-x-small;

        @include media-breakpoint-up(md) {
          width: 40px;
          height: 40px;
          font-size: $font-size-medium;
        }
      }

      .review-content {
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: 80%;
        }
      }
    }

    .review-product-box {
      @include media-breakpoint-up(lg) {
        width: 80%;
      }
    }

    .item-image {
      border-radius: $border-radius-default;

      @include media-breakpoint-up(lg) {
        width: 70px;
        height: 70px;
        object-fit: cover;
      }
    }
  }
}
